import MessageTypes from '../action-types/messages';
import { getLocale } from '../selectors/messages';
import { fetchAll, mapPageCopy, mapACFCollectionByAttribute } from '../utils/cms';
import { handleApiError } from './api';

export const receiveMessages = (messages) => ({
    type: MessageTypes.RECEIVE_MESSAGES,
    payload: messages,
});

export const receiveRichMessages = (messages) => ({
    type: MessageTypes.RECEIVE_RICH_MESSAGES,
    payload: messages,
});

export const setLocale = (locale) => ({
    type: MessageTypes.SET_LOCALE,
    payload: locale,
});

export const fetchMessages = () => async (dispatch, getState) => {
    try {
        const data = await fetchAll('page-copy', getLocale(getState()));
        dispatch(receiveMessages(mapPageCopy(data, 'name')));
    } catch (error) {
        dispatch(handleApiError(error));
    }
};

export const fetchRichMessages = () => async (dispatch, getState) => {
    try {
        const data = await fetchAll('rich-page-copy', getLocale(getState()));
        dispatch(receiveRichMessages(mapACFCollectionByAttribute(data, 'key')));
    } catch (error) {
        dispatch(handleApiError(error));
    }
};

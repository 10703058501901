import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import colors from '../../../../styles/colors';

const MenuDropdown = styled(Menu)(({ theme }) => ({
    '& .MuiMenu-paper': {
        borderRadius: '8px',
        boxShadow: 'none',
        padding: '12px 0',
    },
    '& .MuiMenuItem-root': {
        color: theme.palette.text.primary,
        fontWeight: 600,
        padding: '12px 30px',
        '&:hover': {
            color: 'inherit',
            backgroundColor: colors.aqua,
        },
    },
}));

export default MenuDropdown;

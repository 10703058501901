import * as Sentry from '@sentry/react';
import ApiActions from '../action-types/api';

export const receiveApiError = (error) => ({
    type: ApiActions.RECEIVE_API_ERROR,
    payload: error,
});

export const handleApiError = (error) => async (dispatch) => {
    Sentry.captureException(error);
    return dispatch(receiveApiError(error));
};

import LegalTopicTypes from '../action-types/legal-topics';
import { handleApiError } from './api';
import { fetchAll, mapACFCollectionById } from '../utils/cms';
import search from '../utils/search';
import { getLocale } from '../selectors/messages';

export const recieveLegalTopics = (legalTopics) => ({
    type: LegalTopicTypes.RECEIVE_LEGAL_TOPICS,
    payload: legalTopics,
});

const transformLegalTopics = (legalTopics, locale) => async (dispatch) => {
    const topicsList = Object.values(legalTopics);
    search[locale].addDocuments(topicsList);
    dispatch(recieveLegalTopics(legalTopics));
};

export const fetchLegalTopics = () => async (dispatch, getState) => {
    try {
        const locale = getLocale(getState());
        const data = await fetchAll('legal-topics', locale);
        dispatch(transformLegalTopics(mapACFCollectionById(data), locale));
    } catch (e) {
        dispatch(handleApiError(e));
    }
};

import MessageTypes from '../action-types/messages';

const initial = {
    locale: 'en',
};

const MessagesReducer = (state = initial, action) => {
    const { type, payload } = action;
    switch (type) {
    case MessageTypes.RECEIVE_MESSAGES: {
        return { ...state, ...payload };
    }
    case MessageTypes.RECEIVE_RICH_MESSAGES: {
        return { ...state, richMessages: payload };
    }
    case MessageTypes.SET_LOCALE: {
        return { ...state, locale: payload };
    }
    default:
        return state;
    }
};

export default MessagesReducer;

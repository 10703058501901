import { createSelector } from 'reselect';

export const getMessages = (state) => state.messages;

export const getLocale = createSelector(
    getMessages,
    (messages) => messages.locale,
);

export const getMessagesForName = createSelector(
    getMessages,
    (messages) => (pageName) => messages[pageName],
);

export const getRichMessages = createSelector(
    getMessages,
    (messages) => messages?.richMessages || {},
);

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import { useMessages } from '../../../../utils/cms';
import MenuDropdown from '../menu-dropdown';

const ResourcesDropdown = () => {
    const messages = useMessages('Resources');
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                className="navLink"
                id="resources-button"
                aria-controls={open ? 'resources-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                disableFocusRipple
            >
                {messages.title} <ArrowDropDownIcon />
            </Button>
            <MenuDropdown
                id="resources-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'resources-button',
                }}
            >
                <MenuItem
                    onClick={() => {
                        navigate('/resources?tab=organizations');
                        handleClose();
                    }}
                >
                    {messages.organizationsLabel}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        navigate('/resources?tab=events');
                        handleClose();
                    }}
                >
                    {messages.clinicsLabel}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        navigate('/resources?tab=other');
                        handleClose();
                    }}
                >
                    {messages.otherLabel}
                </MenuItem>
            </MenuDropdown>
        </div>
    );
};

export default ResourcesDropdown;

import React, { Suspense, lazy } from 'react';
import {
    Routes,
    Navigate,
    Route,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme';
import NavBar from '../../components/navbar';
import Footer from '../../components/footer';
import Page from '../../components/page';
import { getLocale } from '../../selectors/messages';
import { useMessages } from '../../utils/cms';

const Home = lazy(() => import('../../routes/home'));
const LegalTopicDetail = lazy(() => import('../../routes/legal-topic'));
const About = lazy(() => import('../../routes/about'));
const Error404 = lazy(() => import('../../routes/error/404'));
const Resources = lazy(() => import('../../routes/resources'));
const MobileMenu = lazy(() => import('../../routes/mobile-menu'));
const Search = lazy(() => import('../../routes/search'));
const LegalIssueDetail = lazy(() => import('../../routes/legal-issue'));
const OrganizationDetail = lazy(() => import('../../routes/organization-detail'));
const EventDetail = lazy(() => import('../../routes/event-detail'));
const UpdateDetail = lazy(() => import('../../routes/update-detail'));
const VideoDetail = lazy(() => import('../../routes/video-detail'));
const TermsAndPrivacy = lazy(() => import('../../routes/terms'));

const PublicLayout = () => {
    const locale = useSelector(getLocale);
    const pageTitles = useMessages('Titles');

    return (
        <Suspense fallback={<div />}>
            <NavBar />
            <main lang={locale}>
                <Routes>
                    <Route path="/error/404" element={<Page title={pageTitles.notFound}><Error404 /></Page>} />
                    <Route path="/about" element={<Page title={pageTitles.about}><About /></Page>} />
                    <Route path="/" element={<Page title={pageTitles.home}><Home /></Page>} />
                    <Route path="/legal-topics/:legalTopicId" element={<LegalTopicDetail />} />
                    <Route path="/legal-topics/:legalTopicId/legal-issues/:legalIssueId" element={<LegalIssueDetail />} />
                    <Route path="/legal-issues/:legalIssueId" element={<LegalIssueDetail />} />
                    <Route path="/events/:eventId" element={<EventDetail />} />
                    <Route path="/resources" element={<Page title={pageTitles.resources}><Resources /></Page>} />
                    <Route path="/videos/:videoId" element={<VideoDetail />} />
                    <Route path="/organizations/:organizationId" element={<OrganizationDetail />} />
                    <Route path="/updates/:updateId" element={<UpdateDetail />} />
                    <Route path="/menu" element={<Page title={pageTitles.menu}><MobileMenu /></Page>} />
                    <Route path="/search" element={<Page title={pageTitles.search}><Search /></Page>} />
                    <Route path="/terms" element={<Page title={pageTitles.terms}><TermsAndPrivacy /></Page>} />
                    <Route path="/*" element={<Navigate replace to="/error/404" />} />
                </Routes>
            </main>
            <Footer />
        </Suspense>
    );
};

export default withRoot(PublicLayout, themer);

import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import useBrowserTracing from 'react-router-v6-instrumentation';
import PublicLayout from '../layouts/public';

const AdminLayout = lazy(() => import('../layouts/admin'));

const App = () => {
    const browserTracing = useBrowserTracing();

    useEffect(() => {
        if (process.env.REACT_APP_SENTRY_DSN) {
            Sentry.init({
                dsn: process.env.REACT_APP_SENTRY_DSN,
                environment: process.env.REACT_APP_SENTRY_ENV,
                integrations: [
                    browserTracing,
                    new CaptureConsoleIntegration(),
                ],

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                tracesSampleRate: 1.0,
            });
        }
    }, []);

    return (
        <Suspense fallback={<div />}>
            <Routes>
                <Route path="/admin" element={<AdminLayout />} />
                <Route path="/*" element={<PublicLayout />} />
            </Routes>
        </Suspense>
    );
};

export default App;

import OrganizationTypes from '../action-types/organizations';

const initial = {
    items: {},
};

const TemplateReducer = (state = initial, action) => {
    const { payload, type } = action;
    switch (type) {
    case OrganizationTypes.LOADING_ORGANIZATIONS:
        return { ...state, isLoading: true };
    case OrganizationTypes.RECEIVE_ORGANIZATIONS:
        return { ...state, isLoading: false, items: payload };
    default:
        return state;
    }
};

export default TemplateReducer;

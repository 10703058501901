import colors from '../../styles/colors';

export default ({
    appBar: ({ theme }) => ({
        height: '100px',
        background: 'none',
        boxShadow: 'none',
        padding: '20px 0',
        marginTop: '64px',
        '& .navTitle': {
            flexGrow: 1,
            color: theme.palette.text.primary,
            fontSize: '1.8rem',
            textDecoration: 'none',
        },
        '& .navIcon': {
            color: theme.palette.text.primary,
            fontSize: '2.4rem',
        },
        '& .navLink': {
            color: theme.palette.text.primary,
            textDecoration: 'none',
            fontWeight: 'bold',
            borderBottom: '2px solid transparent',
            borderRadius: '0',
            padding: 0,
            '&:hover, &:focus-visible': {
                borderBottom: `2px solid ${colors.black}`,
                backgroundColor: 'transparent',
            },
            '&:focus-visible': {
                outline: '#008099 auto 1px',
            },
        },
        '& .navControls': {
            columnGap: '30px',
        },
    }),
    topNav: ({ theme }) => ({
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        padding: 0,
        '& .languageSelection': {
            flexGrow: 1,
            fontSize: '1.5rem',
        },
        '& .quickExit': {
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            textDecoration: 'none',
            fontSize: '1.5rem',

            '&:focus-visible': {
                border: `2px solid ${theme.palette.primary.main}`,
            },
        },
    }),
    menuButton: ({ theme }) => ({
        borderRadius: '8px',
        color: theme.palette.text.primary,
        backgroundColor: 'rgba(217, 224, 231, 0.3)',
        '&:hover': {
            backgroundColor: 'rgba(217, 224, 231, 0.3)',
        },
    }),
    searchButton: ({ theme }) => ({
        width: '124px',
        textAlign: 'left',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.contrastText,
        border: `1px solid ${colors.greyLight}`,
        boxShadow: `0 2 ${colors.greyLight}`,
        justifyContent: 'left',
        borderRadius: '5px',
        padding: '6px 8px',
        '&:hover, &:focus-visible   ': {
            backgroundColor: colors.aqua,
            borderWidth: '1px',
            boxShadow: `0 2px 0 0 ${colors.black}`,
            border: '1px solid #242426',
        },
    }),
});

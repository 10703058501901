import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const IconBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const IconTypography = ({
    icon,
    children,
    columnGap,
    className,
    ...props
}) => (
    <IconBox sx={{ columnGap }} className={`iconTypography ${className}`}>
        {icon}
        <Typography component="div" {...props}>
            {children}
        </Typography>
    </IconBox>
);

IconTypography.propTypes = {
    icon: PropTypes.node,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.string,
    ]),
    columnGap: PropTypes.number,
    className: PropTypes.string,
};

IconTypography.defaultProps = {
    icon: null,
    columnGap: 1,
    className: '',
    children: null,
};

export default IconTypography;

import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import logo from '../../images/logo.svg';
import styles from './styles';
import IconTypography from '../icon-typography';
import { useMessages } from '../../utils/cms';
import Container from '../container';
import SecondaryButton from '../buttons/secondary-button';
import LegalTopicsDropdown from './components/legal-topics-dropdown';
import ResourcesDropdown from './components/resources-dropdown';
import LanguagePicker from './components/language-picker';
import { getLocale } from '../../selectors/messages';

export const StyledAppBar = styled(AppBar)(styles.appBar);
export const TopMenu = styled(AppBar)(styles.topNav);
export const MenuButton = styled(IconButton)(styles.menuButton);
export const SearchButton = styled(Button)(styles.searchButton);

const NavBar = () => {
    const messages = useMessages('Navbar');
    const resourceMessages = useMessages('Resources');
    const handleExit = () => {
        window.open('https://weather.com', '_blank');
        window.location.replace('https://google.com/');
    };
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(890));
    const [searchParams] = useSearchParams();
    const referrer = searchParams.get('cancelTo');
    const location = useLocation();
    const locale = useSelector(getLocale);

    let menu = isSmallScreen ? (
        <>
            <MenuButton aria-label={messages.searchLabel} component={Link} to={`/search?cancelTo=${location.pathname}`}>
                <SearchIcon className="navIcon" />
            </MenuButton>
            <MenuButton component={Link} aria-label={messages.menuTitle} to={`/menu?cancelTo=${location.pathname}`}>
                <MenuIcon className="navIcon" />&nbsp; {messages.menuTitle}
            </MenuButton>
        </>
    ) : (
        <>
            <Link className="navLink" to="/about">{messages.aboutLabel}</Link>
            <LegalTopicsDropdown messages={messages} />
            <ResourcesDropdown />
            <Link className="navLink" to="/resources?tab=updates">{resourceMessages.updatesLabel}</Link>
            <SearchButton component={Link} to={`/search?cancelTo=${location.pathname}`} disableFocusRipple>
                <IconTypography icon={<SearchIcon sx={{ fontSize: '2.0rem' }} />} variant="body2" fontWeight="600">{messages.searchLabel}</IconTypography>
            </SearchButton>
        </>
    );

    if (referrer) {
        menu = (
            <SecondaryButton component={Link} to={referrer}>
                <IconTypography icon={<CloseIcon />} columnGap="5px" fontWeight="bold">{messages.cancelLabel}</IconTypography>
            </SecondaryButton>
        );
    }

    return (
        <Box lang={locale}>
            <TopMenu>
                <Toolbar sx={{ minHeight: '50px !important' }}>
                    <LanguagePicker />
                    <Button className="quickExit" onClick={handleExit} disableFocusRipple disableRipple>
                        {messages.quickExitLabel} <CloseIcon />
                    </Button>
                </Toolbar>
            </TopMenu>
            <StyledAppBar position="static">
                <Container>
                    <Toolbar className="navControls" disableGutters>
                        <Link to="/" className="navTitle">
                            <img src={logo} height="42" width="auto" alt={messages.siteTitle} />
                        </Link>
                        {menu}
                    </Toolbar>
                </Container>
            </StyledAppBar>
        </Box>
    );
};

export default NavBar;

import LegalIssueTypes from '../action-types/legal-issues';
import { getLocale } from '../selectors/messages';
import { mapACFCollectionById, fetchAll } from '../utils/cms';
import search from '../utils/search';
import { handleApiError } from './api';

export const receiveLegalIssues = (legalIssues) => ({
    type: LegalIssueTypes.RECEIVE_LEGAL_ISSUES,
    payload: legalIssues,
});

export const transformLegalIssues = (legalIssues, locale) => async (dispatch) => {
    const issuesList = Object.values(legalIssues);
    search[locale].addDocuments(issuesList);
    dispatch(receiveLegalIssues(legalIssues));
};

export const fetchLegalIssues = () => async (dispatch, getState) => {
    try {
        const locale = getLocale(getState());
        const data = await fetchAll('legal-issues', locale);
        dispatch(transformLegalIssues(mapACFCollectionById(
            data,
            {
                relationshipFields: ['legalTopics', 'legalIssues', 'glossary'],
            },
        ), locale));
    } catch (error) {
        dispatch(handleApiError(error));
    }
};

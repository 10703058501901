export default ({ theme }) => ({
    color: theme.palette.tertiary.contrastText,
    backgroundColor: theme.palette.tertiary.main,
    boxShadow: '1px 1px 7px 0 rgba(0, 0, 0, 0.2)',

    ':hover': {
        backgroundColor: theme.palette.tertiary.light,
    },

    ':focus': {
        backgroundColor: theme.palette.tertiary.light,
    },

    ':active, &[data-clicked=true]': {
        backgroundColor: theme.palette.tertiary.main,
    },
    '&.Mui-disabled': {
        color: `${theme.palette.tertiary.contrastText}`,
        backgroundColor: `${theme.palette.tertiary.main}`,
    },
});

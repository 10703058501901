export default {
    root: ({ theme }) => ({
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        paddingTop: '24px',
        paddingBottom: '24px',

        '& .MuiLink-root': {
            color: theme.palette.secondary.contrastText,
            fontSize: '1.5rem',
            display: 'block',
            marginBottom: '6px',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        '& h6': {
            fontWeight: 700,
            color: theme.palette.tertiary.main,
        },
        '& .iconTypography': {
            marginTop: '16px',
        },
        '& .MuiSvgIcon-root': {
            height: '20px',
            width: '20px',
        },
        '& .MuiTypography-body1, & .MuiTypography-body2': {
            fontSize: '1.5rem',
        },
    }),
    socialLinks: ({ theme }) => ({
        color: theme.palette.tertiary.main,
        display: 'flex',
        columnGap: '12px',
    }),
};

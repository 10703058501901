import {
    Search, StemmingTokenizer, StopWordsTokenizer,
} from 'js-search';
import Tokenizer from 'wink-tokenizer';
import { stemmer } from 'stemmer';

export const englishSearch = new Search('id');
export const spanishSearch = new Search('id');
const winkTokenizer = new Tokenizer();
englishSearch.tokenizer = new StemmingTokenizer(
    stemmer, // Function should accept a string param and return a string
    new StopWordsTokenizer({
        tokenize: (text) => winkTokenizer.tokenize(text).map((token) => token.value),
    }),
);
englishSearch.addIndex('name');
englishSearch.addIndex('description');
englishSearch.addIndex('shortDescription');
englishSearch.addIndex('importantInfo');
englishSearch.addIndex('countiesServed');
englishSearch.addIndex('generalInfoSectionsJSON');

spanishSearch.tokenizer = new StemmingTokenizer(
    stemmer, // Function should accept a string param and return a string
    new StopWordsTokenizer({
        tokenize: (text) => winkTokenizer.tokenize(text).map((token) => token.value),
    }),
);
spanishSearch.addIndex('name');
spanishSearch.addIndex('description');
spanishSearch.addIndex('shortDescription');
spanishSearch.addIndex('importantInfo');
spanishSearch.addIndex('countiesServed');
spanishSearch.addIndex('generalInfoSectionsJSON');

export default {
    en: englishSearch,
    es: spanishSearch,
};

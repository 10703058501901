import KeyMirror from 'keymirror';

const MessageTypes = KeyMirror({
    FETCH_MESSAGES: true,
    RECEIVE_MESSAGES: true,
    SET_LOCALE: true,
    FETCH_RICH_MESSAGES: true,
    RECEIVE_RICH_MESSAGES: true,
});

export default MessageTypes;

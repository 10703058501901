import UpdateTypes from '../action-types/updates';

const initial = {
    items: {},
};

const UpdatesReducer = (state = initial, action) => {
    const { type, payload } = action;
    switch (type) {
    case UpdateTypes.LOADING_UPDATES:
        return { ...state, isLoading: true };
    case UpdateTypes.RECEIVE_UPDATES:
        return { ...state, isLoading: false, items: payload };
    default:
        return state;
    }
};

export default UpdatesReducer;

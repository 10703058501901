import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import { styled } from '@mui/material/styles';
import { getLegalTopicsList } from '../../selectors/legal-topics';
import styles from './styles';
import Container from '../container';
import { useMessages } from '../../utils/cms';
import Logo from '../../images/logo-dark-with-title.svg';
import { getLocale } from '../../selectors/messages';

const FooterContainer = styled(Box)(styles.root);
const SocialMediaLinks = styled(Box)(styles.socialLinks);

const Footer = () => {
    const legalTopics = useSelector(getLegalTopicsList);
    const footerMessages = useMessages('Footer');
    const resourceMessages = useMessages('Resources');
    const locale = useSelector(getLocale);

    return (
        <FooterContainer component="footer" lang={locale}>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={3}>
                        <Box mt={4}>
                            <img src={Logo} width="161" height="auto" alt="Law Help New Mexico" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Typography variant="h6" mb={1} mt={3}>{footerMessages.topicsLabel}</Typography>
                        {legalTopics.map(({ id, name }) => (
                            <Typography key={id}>
                                <Link href={`/legal-topics/${id}`}>{name}</Link>
                            </Typography>
                        ))}
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Typography variant="h6" mt={3} mb={1}>{resourceMessages.title}</Typography>
                        <Link href="/resources?tab=organizations">{resourceMessages.organizationsLabel}</Link>
                        <Link href="/resources?tab=events">{resourceMessages.clinicsLabel}</Link>
                        <Link href="/resources?tab=updates">{resourceMessages.updatesLabel}</Link>
                        <Link href="/resources?tab=other">{resourceMessages.otherLabel}</Link>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Typography variant="h6" mt={3} mb={1}>{footerMessages.contactLabel}</Typography>
                        <Typography mb={1}>
                            {footerMessages.contactPrefix} <Link href="/resources?tab=organizations">{footerMessages.contactPageLink}</Link>
                        </Typography>
                        <SocialMediaLinks mb={3} mt={3}>
                            <Link href={footerMessages.facebookUrl} aria-label="Facebook" target="_blank"><FacebookIcon color="tertiary" /></Link>
                            <Link href={footerMessages.twitterUrl} aria-label="Twitter" target="_blank"><TwitterIcon color="tertiary" /></Link>
                        </SocialMediaLinks>
                        <Box mt={3}>
                            <Link href="/terms">{footerMessages.termsOfUseLabel}</Link>
                            <Typography variant="body2" color="#b1b1b4">{footerMessages.copyright}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </FooterContainer>
    );
};

export default Footer;

import { combineReducers } from 'redux';
import Auth from './auth';
import User from './user';
import LegalTopics from './legal-topics';
import LegalIssues from './legal-issues';
import Organizations from './organizations';
import Events from './events';
import Updates from './updates';
import Resources from './resources';
import Messages from './messages';
import Media from './media';

const Reducers = () => combineReducers({
    auth: Auth,
    user: User,
    legalTopics: LegalTopics,
    legalIssues: LegalIssues,
    organizations: Organizations,
    updates: Updates,
    resources: Resources,
    messages: Messages,
    events: Events,
    media: Media,
});

export default Reducers;

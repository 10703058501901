import LegalIssueTypes from '../action-types/legal-issues';

const initial = {
    items: {},
};

const LegalIssuesReducer = (state = initial, action) => {
    const { type, payload } = action;
    switch (type) {
    case LegalIssueTypes.RECEIVE_LEGAL_ISSUES:
        return { ...state, items: payload };
    default:
        return state;
    }
};

export default LegalIssuesReducer;

import MediaTypes from '../action-types/media';
import WebClient from '../utils/web-client';

export const receiveMedia = (media) => ({
    type: MediaTypes.RECEIVE_MEDIA,
    payload: media,
});

const getAllMedia = async (language) => {
    const url = language === 'en' ? `/${process.env.REACT_APP_API_PREFIX}/media` : `${language}/${process.env.REACT_APP_API_PREFIX}/media`;
    const response = await WebClient.get(url, {
        params: {
            per_page: 100,
        },
    });
    const totalPages = response.headers['x-wp-totalpages'];
    const allFetches = [];
    const allMedia = response.data;
    if (totalPages && totalPages > 1) {
        for (let i = 2; i <= totalPages; i++) {
            allFetches.push(WebClient.get(url, { per_page: 100 }));
        }
        const subsequentResults = [];
        (await Promise.all(allFetches)).forEach(({ data }) => subsequentResults.push(...data));
        allMedia.push(...subsequentResults);
    }
    const mediaById = {};
    allMedia.forEach((media) => {
        mediaById[media.id] = media;
    });

    return mediaById;
};

export const fetchMedia = () => async (dispatch) => {
    const englishMedia = await getAllMedia('en');
    const spanishMedia = await getAllMedia('es');
    // WPML won't give us all media at once, so we need to call for each language
    dispatch(receiveMedia({ ...englishMedia, ...spanishMedia }));
};

import MediaTypes from '../action-types/media';

const initial = {
    items: {},
    isLoading: true,
};

const MediaReducer = (state = initial, action) => {
    const { type, payload } = action;
    switch (type) {
    case MediaTypes.LOADING_MEDIA:
        return { ...state, isLoading: true };
    case MediaTypes.RECEIVE_MEDIA:
        return { ...state, items: payload, isLoading: false };
    default:
        return state;
    }
};

export default MediaReducer;

import { useEffect } from 'react';

const useTitle = (title, config = {}) => {
    const { fallback = '[SITE]', dependencies = [] } = config;
    useEffect(() => {
        document.title = title;

        if (!title) {
            document.title = fallback;
        }
    }, dependencies);
};

export default useTitle;

import ResourceTypes from '../action-types/resources';

const initial = {
    items: {},
};

const ResourceReducer = (state = initial, action) => {
    const { type, payload } = action;
    switch (type) {
    case ResourceTypes.LOADING_RESOURCES:
        return { ...state, isLoading: true };
    case ResourceTypes.RECEIVE_RESOURCES:
        return { ...state, isLoading: false, items: payload };
    default:
        return state;
    }
};

export default ResourceReducer;

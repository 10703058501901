import LegalTopicTypes from '../action-types/legal-topics';

const initial = {
    items: {},
};

const LegalTopicsReducer = (state = initial, action) => {
    const { payload, type } = action;
    switch (type) {
    case LegalTopicTypes.RECEIVE_LEGAL_TOPICS:
        return { ...state, items: payload };
    default:
        return state;
    }
};

export default LegalTopicsReducer;

import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import { getLocale } from './messages';
import { getTranslatedModel } from '../utils/cms';

export const getLegalTopics = (state) => state.legalTopics.items;

export const getLegalTopicsList = createSelector(
    getLegalTopics,
    (legalTopics) => orderBy(Object.values(legalTopics), ['globalOrder'], ['asc']) || [],
);

export const getTranslatedLegalTopic = createSelector(
    getLegalTopics,
    getLocale,
    (legalTopics, locale) => (id) => getTranslatedModel(id, legalTopics, locale),
);

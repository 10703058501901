import React from 'react';
import PropTypes from 'prop-types';
import MuiContainer from '@mui/material/Container';

const Container = ({ children, ...props }) => (
    <MuiContainer maxWidth="xl" {...props}>
        {children}
    </MuiContainer>
);

Container.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default Container;

import { createTheme } from '@mui/material/styles';
import Colors from './colors';
// https://material-ui.com/customization/default-theme/?expend-path=$.palette.background
// https://fonts.adobe.com/fonts/ibm-plex-sans#fonts-section
const baseTheme = createTheme({
    breakpoints: { // using Twitter bootstrap defined breakpoints
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    palette: {
        text: {
            primary: Colors.black,
            secondary: Colors.greyDarker,
        },
        primary: {
            main: Colors.yellow,
            contrastText: Colors.black,
        },
        secondary: {
            main: Colors.black,
            contrastText: Colors.white,
        },
        tertiary: {
            main: Colors.vibrantBlue,
            dark: Colors.blueDark,
            light: Colors.blueLight,
            contrastText: Colors.white,
        },
        quaternary: {
            main: Colors.grey,
            dark: Colors.greyDark,
            contrastText: Colors.black,
        },
        background: {
            default: Colors.whiteDark,
        },
        error: {
            main: Colors.error,
        },
        success: {
            main: Colors.success,
        },
    },
});
const theme = createTheme(baseTheme, {
    typography: {
        fontFamily: '"Readex Pro", sans-serif',
        h1: {
            fontFamily: '"Readex Pro", sans-serif',
            fontSize: '3.2rem',
            fontWeight: 700,
            fontStyle: 'normal',
            lineHeight: '3.7rem',
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '4rem',
                lineHeight: '4rem',
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: '4.6rem',
                lineHeight: '4.6rem',
            },
        },
        h2: {
            fontFamily: '"Readex Pro", sans-serif',
            fontSize: '2.4rem',
            fontWeight: 700,
            fontStyle: 'normal',
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '2.8rem',
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: '3.2rem',
            },
        },
        h3: {
            fontFamily: '"Readex Pro", sans-serif',
            fontWeight: 700,
            fontSize: '1.8rem',
            [baseTheme.breakpoints.up('sm')]: {
                fontSize: '2.0rem',
            },
            [baseTheme.breakpoints.up('lg')]: {
                fontSize: '2.0rem',
            },
        },
        h4: {
            fontFamily: '"Readex Pro", sans-serif',
            fontSize: '1.6rem',
            fontWeight: 700,
        },
        h5: {
            fontFamily: '"Readex Pro", sans-serif',
            fontSize: '1.6rem',
            fontWeight: 600,
        },
        h6: {
            fontFamily: '"Readex Pro", sans-serif',
            fontSize: '1.8rem',
            fontWeight: 700,
        },
        body1: {
            fontFamily: '"Readex Pro", sans-serif',
            fontSize: '1.7rem',
            lineHeight: '2.3rem',
        },
        body2: {
            fontFamily: '"Inter", sans-serif',
            fontSize: '1.6rem',
            lineHeight: '2.4rem',
            color: Colors.greyDarker,
        },
        button: {
            fontFamily: '"Readex Pro", sans-serif',
            textTransform: 'none',
            fontSize: '1.6rem',
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    [baseTheme.breakpoints.up('xl')]: {
                        maxWidth: '1200px',
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: Colors.deepRed,
                    textDecoration: 'none',
                    borderRadius: '5px',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                    '&:focus-visible': {
                        border: '2px solid #008099',
                        outline: 'none',
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: baseTheme.palette.tertiary.light,
                    height: 'auto',
                    padding: '8px',
                    borderRadius: '3px',
                    fontSize: '1.6rem',
                    border: '1px solid rgba(122,208,222,0.5)',
                    '&:hover, &:focus-visible': {
                        backgroundColor: baseTheme.palette.tertiary.light,
                    },
                },
                label: {
                    padding: 0,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: '16px',
                    border: 'none',
                    boxShadow: 'none',
                    borderRadius: '12px',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiFormControlLabel: { // checkboxes, radio buttons
            styleOverrides: {
                label: {
                    color: baseTheme.palette.text.secondary,
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    lineHeight: 'inherit',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    textTransform: 'uppercase',
                    letterSpacing: '0.0625rem',
                    position: 'relative',
                    top: 0,
                    left: 0,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    top: -15,
                    left: -15,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                    lineHeight: '1.4rem',
                    letterSpacing: '0.1rem',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.white,
                    fontSize: '1.6rem',
                    marginTop: 5,
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '2px solid #008099',
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation0: { // select menus
                    border: `2px solid ${Colors.greyLight}`,
                    borderRadius: 5,
                    paddingTop: 3,
                    paddingBottom: 3,
                },
                elevation2: { // regular menus
                    borderRadius: 5,
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
                },
                elevation24: { // dialogs
                    borderRadius: 5,
                    boxShadow: `0 0 10px 0 rgba(${Colors.blueDarkRGB}, 0.2)`,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.text.secondary,
                    lineHeight: 1.1875,
                    fontSize: '1.6rem',

                    '&:hover': {
                        color: baseTheme.palette.primary.main,
                    },

                    '&[aria-selected=true]': {
                        color: baseTheme.palette.primary.contrastText,
                        backgroundColor: baseTheme.palette.primary.main,
                    },

                    '&[role=option]': { // select menu options
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 22,
                        paddingRight: 22,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '8px 16px',
                    borderWidth: '2px',
                    borderRadius: '80px',
                    '&:hover': {
                        borderWidth: '2px',
                    },
                },
                outlinedSecondary: {
                    borderColor: baseTheme.palette.secondary.main,
                },
                contained: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                containedSecondary: {
                    borderRadius: '5px',
                    fontWeight: 'bold',
                    border: `3px solid ${baseTheme.palette.secondary.main}`,
                    '&:hover, :focus': {
                        backgroundColor: '#000000',
                        border: '3px solid #000000',
                        textDecoration: 'none',
                    },
                    '&:focus-visible': {
                        backgroundColor: baseTheme.palette.secondary.main,
                        border: '3px solid #008099',
                        boxShadow: '0 2px 4px 0 #96EEF1',
                        textDecoration: 'none',
                    },
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                primary: {

                    '&.Mui-disabled, &[aria-disabled=true]': {
                        opacity: '40%',
                        backgroundColor: baseTheme.palette.primary.main,
                        color: baseTheme.palette.primary.contrastText,
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '1.5rem',
                    fontFamily: '"Inter", sans-serif',
                    backgroundColor: baseTheme.palette.tertiary.dark,
                },
                arrow: {
                    color: baseTheme.palette.tertiary.dark,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: baseTheme.palette.quaternary.main,
                        borderRadius: '8px 8px 0 0',
                    },
                    '&.Mui-focusVisible, &.Mui-focusVisible.Mui-selected': {
                        border: '2px solid #008099',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    '@media (hover: hover)': {
                        '&:hover': {
                            backgroundColor: Colors.aqua,
                        },
                    },
                },
            },
        },
    },
});

export default theme;

import EventTypes from '../action-types/events';

const initial = {
    items: {},
};

const EventsReducer = (state = initial, action) => {
    const { type, payload } = action;
    switch (type) {
    case EventTypes.LOADING_EVENTS:
        return { ...state, isLoading: true };
    case EventTypes.RECEIVE_EVENTS:
        return { ...state, isLoading: false, items: payload };
    default:
        return state;
    }
};

export default EventsReducer;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { getLocale } from '../../../../selectors/messages';
import { fetchMessages, setLocale } from '../../../../actions/messages';
import { fetchLegalTopics } from '../../../../actions/legal-topics';
import { fetchLegalIssues } from '../../../../actions/legal-issues';
import { fetchMedia } from '../../../../actions/media';
import colors from '../../../../styles/colors';

const LanguageButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.contrastText,
    fontWeight: 300,
    '&.active': {
        fontWeight: 'bold',
    },
    '&:focus-visible': {
        border: `2px solid ${colors.aqua}`,
    },
}));

const LanguageButtonContainer = styled(Box)(() => ({
    flex: 1,
}));

const LanguagePicker = () => {
    const locale = useSelector(getLocale);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchLegalTopics());
        dispatch(fetchMessages());
        dispatch(fetchLegalIssues());
        dispatch(fetchMedia());
    }, [locale]);

    return (
        <LanguageButtonContainer>
            <LanguageButton onClick={() => dispatch(setLocale('en'))} className={locale === 'en' && 'active'} disableFocusRipple disableRipple>
                English
            </LanguageButton>
            <LanguageButton onClick={() => dispatch(setLocale('es'))} className={locale === 'es' && 'active'} disableFocusRipple disableRipple>
                Espa&ntilde;ol
            </LanguageButton>
        </LanguageButtonContainer>
    );
};

export default LanguagePicker;

export default {
    white: '#ffffff',
    whiteRGB: '255, 255, 255',
    whiteDark: '#F4F4F5',
    black: '#242426',
    grey: '#D9E0E7',
    greyRGB: '195, 196, 196',
    greyDark: '#CCCCCC',
    greyDarker: '#666666',
    greyLight: '#DDDDDD',
    greyLightRGB: '233,234,235',
    vibrantBlue: '#7AD0DE',
    blue: '#4D73C8',
    blueHighlight: '#5A86EB',
    blueDark: '#008099',
    blueDarkRGB: '12, 15, 54',
    blueLight: '#d1f1f2',
    yellow: '#FFD75F',
    aqua: '#d0f1f2',
    deepRed: '#9D0024',
    error: '#C24444',
    success: '#13AE47',
    paleGreen: '#7FD7A8',
    orange: '#FE894A',
};

import { useSelector } from 'react-redux';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import { getMessagesForName } from '../selectors/messages';
import WebClient from './web-client';

export const getLocale = (wpmlLocale) => {
    if (wpmlLocale === 'en_US') {
        return 'en';
    }
    if (wpmlLocale === 'es_ES') {
        return 'es';
    }
    return '';
};

export const transformACFModel = (acfModel) => {
    const locale = getLocale(acfModel.wpml_current_locale);
    const translations = {};
    acfModel.wpml_translations.forEach((translation) => {
        const translatedLocale = getLocale(translation.locale);
        translations[translation.id] = translatedLocale;
    });

    return {
        ...acfModel.acf,
        id: acfModel.id,
        postType: acfModel.type,
        globalOrder: acfModel.menu_order,
        createdAt: acfModel.date,
        locale,
        translations,
    };
};

export const normalizeEmptyRelationships = (model, relationshipFields) => {
    const modelToReturn = { ...model };
    relationshipFields.forEach((field) => {
        if (!modelToReturn[field]) {
            modelToReturn[field] = [];
        }
    });
    return modelToReturn;
};

export const mapACFCollectionById = (acfCollection, options = {}) => {
    const { relationshipFields = [] } = options;
    const finalMap = {};
    acfCollection.forEach((acfModel) => {
        finalMap[acfModel.id] = normalizeEmptyRelationships(
            transformACFModel(acfModel),
            relationshipFields,
        );
    });
    return finalMap;
};

export const mapACFCollectionByAttribute = (acfCollection, attribute, options = {}) => {
    const { relationshipFields = [] } = options;
    const finalMap = {};
    acfCollection.forEach((acfModel) => {
        finalMap[acfModel.acf[attribute]] = normalizeEmptyRelationships(
            transformACFModel(acfModel),
            relationshipFields,
        );
    });
    return finalMap;
};

export const useMessages = (pageName) => {
    const getMessages = useSelector(getMessagesForName);
    const messages = getMessages(pageName);
    return messages || {};
};

export const formatEventDate = (date, startTime, endTime) => {
    const dateObject = parse(date, 'yyyyMMdd', new Date());
    if (!startTime) {
        return format(dateObject, 'EEE, LLL d');
    }
    const startTimeDate = parse(startTime, 'HH:mm:ss', new Date());
    if (!endTime) {
        return `${format(dateObject, 'EEE, LLL d,')} ${format(startTimeDate, 'h:mm aa')}`;
    }
    const endTimeDate = parse(endTime, 'HH:mm:ss', new Date());
    return `${format(dateObject, 'EEE, LLL d,')} ${format(startTimeDate, 'h:mm aa')} - ${format(endTimeDate, 'h:mm aa')}`;
};

export const fetchAll = async (resourceSlug, language = 'en', config = {}) => {
    const { params } = config;
    const url = language === 'en' ? `/${process.env.REACT_APP_API_PREFIX}/${resourceSlug}` : `${language}/${process.env.REACT_APP_API_PREFIX}/${resourceSlug}`;
    const response = await WebClient.get(url, {
        ...config,
        params: {
            ...params,
            per_page: 100,
        },
    });
    const totalPages = response.headers['x-wp-totalpages'];
    const allFetches = [];
    if (totalPages && totalPages > 1) {
        for (let i = 2; i <= totalPages; i++) {
            allFetches.push(WebClient.get(url, {
                ...config,
                params: {
                    ...params,
                    per_page: 100,
                    page: i,
                },
            }));
        }
        const subsequentResults = [];
        (await Promise.all(allFetches)).forEach(({ data }) => subsequentResults.push(...data));
        return response.data.concat(subsequentResults);
    }
    return response.data;
};

export const getTranslatedModel = (id, collection, locale) => {
    let model = collection[id];
    // if the model was found, it must be either this locale or the default language fallback
    if (model) {
        return model;
    }
    // check to see if this model has an associated translation
    model = Object.values(collection).find((item) => item.translations[id] && item.locale === locale);
    return model || {};
};

/* a special mapping function that takes ACF models representing page-copy and distills them to a model of the following structure:
* {
*    PageName: {
*       messageKey: messageValue,
*       ...
*   }
* }
*/
export const mapPageCopy = (messages) => {
    const mappedCopy = {};
    messages.forEach((page) => {
        mappedCopy[page.acf.name] = {};
        page.acf.messages.forEach((message) => {
            mappedCopy[page.acf.name][message.key] = message.message;
        });
    });
    return mappedCopy;
};

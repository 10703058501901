import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import noop from '../../../utils/noop';

const SecondaryButton = ({ children, onClick, ...props }) => (
    <Button
        variant="contained"
        color="secondary"
        onClick={onClick}
        disableRipple
        disableFocusRipple
        {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
        { children }
    </Button>
);

SecondaryButton.defaultProps = {
    children: null,
    onClick: noop,
};

SecondaryButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
};

export default SecondaryButton;
